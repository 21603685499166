import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import window from 'global'
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
          title="福大同-蔡子晴 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "福大同茶莊是目前萬華最老的商號之一，經營茶葉買賣已有180年歷史。",
            },
            {
              property: `og:title`,
              content: "福大同-蔡子晴 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "福大同茶莊是目前萬華最老的商號之一，經營茶葉買賣已有180年歷史。",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/project_a_muse_6.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;